import "./index.scss";

const BottomAlert = () => {
  return (
    <div className="btm-container">
      <p>
        This site is WIP | Production Testing. It may not fully function and may
        give you some &gt;bugs&lt;🐞
      </p>
      <p>
        Find the typo in website and message, first Alert will get the reward 🚀
      </p>
    </div>
  );
};

export default BottomAlert;
